import React from "react"
import { Link } from "gatsby"

// Import Images
import RooamFullLogo from '../images/rooam-full-logo.svg'
import Logo from '../images/rooam-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/partners"

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

const About = () => {
   
  return (
    <Layout>
      <SEO title="About us - Rooam" />
      <div className="section more-top">
        <div className="container medium">
          <div className="peak-wrap no-top-bottom text-center">
            <div className="icon full-logo">
              <Fade bottom distance={'1em'} duration={800} delay={300}>
                <img src={RooamFullLogo} alt="Rooam Logo" />
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container medium">
          <p className="lead text-white font-weight-600">Rooam was built to bring mobile payments into hospitality and eliminate the worst part of any night out: paying the bill. By creating an instant, contactless payment solution we hoped to solve concrete problems for both businesses and their guests. Our solution is simple: allow guests to view and pay their check directly from their phone.</p>
          <p className="lead text-light-grey font-weight-400">In order to achieve widespread B2B success, we knew the platform must integrate directly into every major point-of-sale system while preserving a natural workflow for bartenders and servers. Today, Rooam integrates with Micros, Aloha, POSitouch, Toast, Upserve and many others. In addition, we have established a national ecosystem by partnering with nationally recognized restaurant groups, enterprise hospitality groups, music venue operators and festivals. Rooam is now the leading mobile payment platform.</p>
          <p className="lead text-light-grey font-weight-400">Over the past two years, rapid growth and success on both sides of the bar has validated our original mission. With Rooam, venue partners see reduced lines/crowds at the bar, significantly increased spending per guest, and more secure transactions. Guests at restaurants  have a better night out because they are served faster, can leave when ready and don’t have to worry about losing a credit card or carrying cash.</p>
          <p className="lead text-light-grey font-weight-400">Our focus on solving existing problems specific to hospitality has allowed Rooam to become the most widely used mobile payment company in the industry.</p>
        </div>
      </div>

      <div className="section">
        <Partners/>
      </div>

      <div className="section no-top">
        <div className="container medium">
          <h3 className="more text-white no-top">The latest press</h3>
          <ul className="strips">
            <li className="background-dark-grey">
              <a href="https://www.azcentral.com/story/entertainment/music/2020/11/17/tempe-marquee-theatre-concerts/6312924002/" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">Tempe's Marquee Theatre is reopening after COVID-19 closure. Here's what you need to know <span className="text-grey font-weight-400">&mdash; Arizona Central.</span></p>
                </div>
              </a>
            </li>
            <li className="background-dark-grey">
              <a href="https://www.nba.com/kings/news/sacramento-kings-and-golden-1-center-launch-first-nba-curbside-pickup-menu-program-featuring" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">Sacramento Kings and Golden 1 Center Launch First NBA Curbside Pickup Menu Program Featuring Contactless Payment Powered by Rooam <span className="text-grey font-weight-400">&mdash; Sacramento Kings &amp; NBA.</span></p>
                </div>
              </a>
            </li>
            <li className="background-dark-grey">
              <a href="https://www.washingtoncitypaper.com/food/article/21043725/rooam-lets-you-skip-the-annoying-task-of-closing-out-a-tab-or-signing-a-check" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">Rooam Lets You Skip The Annoying Task of Closing Out a Tab or Signing a Check <span className="text-grey font-weight-400">&mdash; Washington City Paper.</span></p>
                </div>
              </a>
            </li>
            <li className="background-dark-grey">
              <a href="https://www.americaninno.com/dc/startups-to-watch" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">The 19 D.C. Startups to Watch in 2019 <span className="text-grey font-weight-400">&mdash; AmericanInno.</span></p>
                </div>
              </a>
            </li>
            <li className="background-dark-grey">
              <a href="https://technical.ly/dc/2018/11/01/rooam-is-expanding-its-digital-payment-platform-to-chicago-and-la-this-winter/" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">Rooam is expanding its digital payment platform to Chicago and LA this winter <span className="text-grey font-weight-400">&mdash; Technical.ly D.C.</span></p>
                </div>
              </a>
            </li>
            <li className="background-dark-grey">
              <a href="https://www.prweb.com/releases/rooam_acquires_tally_and_launches_chicago_operations_this_winter/prweb15878546.htm" target="_blank" rel="noopener noreferrer">
                <div className="strip-single">
                  <span className="colored-line"></span>
                  <p className="lead text-white font-weight-500 no-top-bottom">Rooam Acquires Tally and Launches Chicago Operations this Winter <span className="text-grey font-weight-400">&mdash; Chicago Business Journal.</span></p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="section less-top no-bottom">
          <div className="container small text-center">
            <p className="text-grey no-top-bottom">If you’re a member of the media, and would like to talk to us, please contact <a href="mailto:hello@rooam.co" target="_blank" className="text-link-white" rel="noopener noreferrer">hello@rooam.co</a>.</p>
            <a className="btn light no-bottom-margin" href="https://media.rooam.co/website/downloads/Rooam-MediaKit.zip" target="_blank" rel="noopener noreferrer">Download Press Kit</a>
          </div>
        </div>
      </div>

      <div className="section no-top">
        <div className="container text-center">
          <div className="peak-wrap no-top">
            <div className="icon logo">
              <Link to="/"><img src={Logo} alt="Rooam Logo" /></Link>
            </div>
          </div>
          <h2 className="no-top-bottom">
            <span className="text-white">Rooam is built for your business.</span><br/>
            <span className="text-grey">Go live today.</span>
          </h2>
          <Link to="/request-demo/" className="btn light no-bottom-margin">Request Demo</Link>
          <a className="btn dark no-bottom-margin" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">
            Contact Sales
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default About
